<template>
  <section
    class="configure-company-document"
    id="drag-template-background"
    v-loading="documentDataLoading"
  >
    <div class="inner-navbar" v-if="documentData">
      <el-row type="flex" align="middle" justify="center">
        <el-col :span="7">
          <el-button round icon="el-icon-back" @click="goToDocuments()"
            >Back to Documents</el-button
          >
        </el-col>
        <el-col :span="10">
          <h2 class="font-weight-normal blue-2">{{ documentData.title }}</h2>
        </el-col>
        <el-col :span="6">
          <div class="float-right">
            <el-button class="btn" type="success" @click="saveDocumentData()">
              <i class="el-icon-s-claim"></i> SAVE &amp; UPDATE
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="input-options">
      <el-row type="flex" justify="center">
        <el-col :span="17">
          <div v-if="documentData">
            <el-card class="download-card">
              <h3 class="font-weight-normal blue">
                <i class="el-icon-document"></i> Document
              </h3>
              <el-button @click="onDownload" type="primary" round>
                <i class="el-icon-download pr-1"></i> Download
              </el-button>
            </el-card>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="form-fields-set">
            <el-row>
              <h3 class="font-weight-normal text-center blue mb-05">
                Document Details
              </h3>
              <el-form v-model="userDocumentFormData">
                <el-form-item label="Document Valid From">
                  <el-date-picker
                    type="date"
                    v-model="userDocumentFormData.valid_from"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item label="Document Expires">
                  <el-date-picker
                    type="date"
                    v-model="userDocumentFormData.expires_at"
                  ></el-date-picker>
                </el-form-item>
                <el-divider class="mb-05"></el-divider>
                <el-form-item label="E-signature Required ?" class="mb-0">
                  <el-radio-group
                    v-model="userDocumentFormData.e_signature_value"
                    @change="updateEsignValue"
                  >
                    <el-radio :label="'YES'">Yes</el-radio>
                    <el-radio :label="'NO'">No</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>

              <div
                class="signature-box mb-0"
                v-if="
                  documentSentStatus &&
                  userDocumentFormData.e_signature_required
                "
              >
                <el-card shadow="never" class="mt-1 bg-light">
                  <div
                    class="float-right"
                    v-if="userDocumentFormData.e_signature_status"
                  >
                    <div class="success">
                      <i class="el-icon-finished"></i> Completed
                    </div>
                  </div>
                  <div
                    class="float-right"
                    v-if="!userDocumentFormData.e_signature_status"
                  >
                    <div class="error">Pending</div>
                  </div>
                  <h4 class="font-weight-normal">E-Signature</h4>
                  <el-divider class="mt-05 mb-05"></el-divider>
                  <div
                    class="text-center"
                    v-if="userDocumentFormData.e_signature_status"
                  >
                    <el-button
                      class="btn-link p-0"
                      type="text"
                      @click="onESignView"
                      >View Signature</el-button
                    >
                  </div>
                </el-card>
              </div>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-dialog :visible.sync="showSavedSignatureData" title="eSignature">
      <div v-loading="esignLoading">
        <el-row>
          <el-col :span="24" v-if="esignData">
            <ESignatureDataView :esignData="esignData"></ESignatureDataView>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import appConfig from "@/config/app";
import { mapGetters } from "vuex";
import ESignatureDataView from "@/components/signature/ESignatureDataView";

export default {
  components: {
    ESignatureDataView,
  },

  created() {},
  data() {
    return {
      elements: [],
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      addItemDataDialogVisible: false,
      selectedItemIndex: null,
      selectedItem: {},
      refreshForm: false,
      selectedSignatureItem: {},
      selectedSignatureItemIndex: -1,
      captureSignatureDialog: false,
      hasBackground: false,
      documentId: null,
      configurableDocumentId: null,
      documentDataLoading: false,
      documentFormData: null,
      documentName: "",
      loading: false,
      backgroundImage: "",
      previewVisible: false,
      activatedItem: null,
      activatedItemIndex: -1,

      currentFormBuilderWidth: null,
      storedFormBuilderWidth: null,
      checkExistingData: false,
      backgroundImages: [],
      loadingElements: false,
      selectedTemplateFields: [],
      templatesUsed: [],
      showErrorElementDialog: false,
      errorElements: [],
      fieldErrorMessage: "",
      workflowDataId: "",
      userId: "",

      userDocumentFormData: {
        valid_from: "",
        expires_at: "",
        e_signature_required: "",
        e_signature_value: "",
        e_signature_status: false,
      },
      documentSentStatus: false,

      employeeDocumentId: "",
      documentData: {},
      workflowStepId: "",
      showSavedSignatureData: false,
      esignLoading: false,
      esignData: {},
    };
  },
  computed: {
    ...mapGetters("company", ["getCompanyProfileData"]),
    ...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
    ...mapGetters("employeeData", ["getUserTemplatesData", "getESignData"]),
    ...mapGetters("workflowData", [
      "getWorkflowDataAssetDataAddStatus",
      "getUserWorkflowData",
    ]),
    ...mapGetters("companyDocuments", [
      "getCompanyDocumentDataById",
      "getDownloadUrl",
      "getDownloadError",
    ]),
    ...mapGetters("employeeDocuments", [
      "getEmployeeDocumentDataById",
      "getEmployeeDocumentUpdateStatus",
    ]),

    getLogoUrl() {
      return appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;
    },
  },
  async mounted() {
    this.fetchCompanyDetails();
    this.setData();
    await this.fetchDocumentData();
    if (this.employeeDocumentId) {
      await this.fetchEmployeDocumentData();
      this.setEmployeeDocumentData();
    }
    await this.fetchWorkflowData();
  },
  methods: {
    setData() {
      this.employeeDocumentId = this.$route.query.employee_document_id;
      this.configurableDocumentId = this.$route.params.configurable_document_id;
      this.documentId = this.$route.params.document_id;
      this.workflowStepId = this.$route.query.workflow_step_id;
    },

    async fetchWorkflowData() {
      this.workflowDataId = this.$route.params.workflow_data_id;

      if (this.workflowDataId) {
        this.userId =
          this.getUserWorkflowData && this.getUserWorkflowData.user_id
            ? this.getUserWorkflowData.user_id
            : null;

        let steps = this.getUserWorkflowData.workflow_steps_data || [];

        this.documentSentStatus = steps.find(
          (x) => x.step_id == this.workflowStepId && x.sent_status
        )
          ? true
          : false;
      }

      if (this.userId && this.templatesUsed.length) {
        await this.fetchUserTemplateData();
      }
    },

    setEmployeeDocumentData() {
      if (this.employeeDocumentId && this.getEmployeeDocumentDataById) {
        this.userDocumentFormData.e_signature_required = this.getEmployeeDocumentDataById.e_signature_required;
        this.userDocumentFormData.e_signature_status = this.getEmployeeDocumentDataById.e_signature_status;
        this.userDocumentFormData.expires_at = this.getEmployeeDocumentDataById.expires_at;
        this.userDocumentFormData.valid_from = this.getEmployeeDocumentDataById.valid_from;
        this.userDocumentFormData.e_signature_value = this
          .getEmployeeDocumentDataById.e_signature_required
          ? "YES"
          : "NO";
      }
    },

    async fetchCompanyDetails() {
      await this.$store.dispatch("company/getCompany");
    },
    async fetchEmployeeData() {
      this.empDataLoading = true;
      await this.$store.dispatch("employee/fetchEmployeeSingleEmployee", {
        id: this.employeeId,
      });
      this.empDataLoading = false;
    },
    async fetchDocumentData() {
      this.documentDataLoading = true;

      await this.$store.dispatch("companyDocuments/fetchCompanyDocumentById", {
        id: this.documentId,
      });

      this.userDocumentFormData.valid_from = this.getCompanyDocumentDataById.valid_from;
      this.userDocumentFormData.expires_at = this.getCompanyDocumentDataById.valid_to;
      this.userDocumentFormData.e_signature_required = this.getCompanyDocumentDataById.e_signature_required;
      this.userDocumentFormData.e_signature_value = this
        .getCompanyDocumentDataById.e_signature_required
        ? "YES"
        : "NO";
      this.documentData = this.getCompanyDocumentDataById;

      this.documentDataLoading = false;
    },

    async onDownload() {
      await this.$store.dispatch("companyDocuments/downloadCompanyDocument", {
        id: this.documentId,
      });
      if (this.getDownloadUrl) {
        window.open(this.getDownloadUrl, "_blank");
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
    },

    async fetchEmployeDocumentData() {
      await this.$store.dispatch(
        "employeeDocuments/fetchEmployeeDocumentById",
        {
          id: this.employeeDocumentId,
        }
      );
    },

    goToDocuments() {
      this.$router.go(-1);
    },
    saveDocumentData() {
      try {
        if (this.employeeDocumentId) {
          this.updateEmployeeDocument();
        } else {
          this.addEmployeeDocument();
        }
      } catch (err) {
        console.log("saveDocumentData",err);
      }
    },

    async addEmployeeDocument() {
      try {
        let companyDocumentData = {
          company_document_id: this.documentId,
          document_type: "STATIC",
          valid_from: this.userDocumentFormData.valid_from,
          expires_at: this.userDocumentFormData.expires_at,
          e_signature_required:
            this.userDocumentFormData.e_signature_value == "YES" ? true : false,
        };
        let params = {
          workflow_data_id: this.workflowDataId,
          asset: "COMPANY_DOCUMENTS",
          workflow_step_id: this.workflowStepId,
          company_document_data: companyDocumentData,
        };
        await this.$store.dispatch(
          "workflowData/addAssetDataToWorkFlowData",
          params
        );
        if (this.getWorkflowDataAssetDataAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document Data Saved Successfully",
          });
          this.goToDocuments();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Erorr at updating document",
          });
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Erorr at updating document",
        });
      }
    },
    async updateEmployeeDocument() {
      try {
        let params = {
          id: this.employeeDocumentId,
          valid_from: this.userDocumentFormData.valid_from,
          expires_at: this.userDocumentFormData.expires_at,
          e_signature_required:
            this.userDocumentFormData.e_signature_value == "YES" ? true : false,
        };

        await this.$store.dispatch(
          "employeeDocuments/updateEmployeeDocument",
          params
        );

        if (this.getEmployeeDocumentUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document Data Saved Successfully",
          });
          this.goToDocuments();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Erorr at updating document",
          });
        }
      } catch (err) {
        console.log("updateEmployeeDocument",err);
      }
    },
    async onESignView() {
      try {
        this.showSavedSignatureData = true;
        this.esignLoading = true;
        let params = {
          id: this.getEmployeeDocumentDataById.e_signature,
        };
        await this.$store.dispatch("employeeData/fetchESignData", params);
        this.esignLoading = false;
        this.esignData = this.getESignData;
      } catch (err) {
        console.log("onESignView",err);
      }
    },
    updateEsignValue(value) {
      this.userDocumentFormData.e_signature_required = value === "YES";
    },
  },
  beforeDestroy() {
    this.$store.commit("company/setCompanyProfileData",null,{root:true})
    this.$store.commit("employeeDocuments/setEmployeeDocumentUpdateStatus",null,{root:true})
    this.$store.commit("employeeDocuments/setEmployeeDocumentDataById",null,{root:true})
    this.$store.commit("companyDocuments/setCompanyDocumentDataById",null,{root:true})
    this.$store.commit("companyDocuments/setDownloadUrl",null,{root:true})
    this.$store.commit("companyDocuments/setDownloadError",null,{root:true})
    this.$store.commit("employeeData/setESignData",null,{root:true})
    this.$store.commit("employeeData/setUserTemplatesData",null,{root:true})
    this.$store.commit("configurableDocuments/setConfigurableDocumentDataById",null,{root:true})
    this.$store.commit("workflowData/setWorkflowDataAssetDataAddStatus",null,{root:true})
    this.$store.commit("workflowData/setUserWorkflowData",null,{root:true})
  }
};
</script>
